import React, { useState, useContext, createContext, FC, FunctionComponent, ReactNode } from 'react';

const AdminPage = () => {
    
    return (
        <div>
            admin page
        </div>
    )
}
export default AdminPage