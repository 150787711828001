import styled from "styled-components";

export const Container = styled.div`
    // padding: 10px 150px 200px 200px;
    
    `
    export const List = styled.ul`
    list-style-type: none;
    padding: 0;
    width: 100%;
    `
export const ListItemContainer = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin: 10px;
    border: solid 1px grey;
    background-color: beige;
    `